
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
    
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card>
            <v-card-text>
                <v-data-table :headers="headers"    :search="search" :items="rows" class="elevation-0"
                    item-key="">
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-flex>
</v-layout>
</v-container>
</div>
</template>
<script>
    import requests from './../../requests/item_types_view.request.js'
    export default {
        data() {
            return {
                item_types_view: {},
                search: '',
                loading : true,
                
                rows:[],
                headers: [

                    
                        { 
                            text: this.$store.getters.language.data.item_types_view.item_type_id,
                            align: 'start',
                            sortable: true,
                            value: 'item_type_id',
                        },
                        { 
                            text: this.$store.getters.language.data.item_types_view.item_type_title,
                            align: 'start',
                            sortable: true,
                            value: 'item_type_title',
                        },
                        { 
                            text: this.$store.getters.language.data.item_types_view.user_id,
                            align: 'start',
                            sortable: true,
                            value: 'user_id',
                        },
                        { 
                            text: this.$store.getters.language.data.item_types_view.user_username,
                            align: 'start',
                            sortable: true,
                            value: 'user_username',
                        },   {
            text: this.$store.getters.language.data.actions,
            align: 'start',
            sortable: true,
            value: '',
        }
                ],
            }
        },
        mounted(){
            this.readItemTypesView();
        },
        methods: {
            readItemTypesView() {
                this.loading = true
                requests.getAllItemTypesView().then(r => {
                    if (r.status == 200) {
                        this.rows = r.data.rows
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read ItemTypesView',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read ItemTypesView',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    