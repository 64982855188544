
import axios from 'axios';
export default {
    
    async reportItemTypesView(column , value)  {
        return await axios.get(`item_types_view/report?${column}=${value}`)
    },
    async getAllItemTypesView()  {
        return await axios.get(`item_types_view/all`)
    },

    async getItemTypesViewByColumn(column , value)  {
        return await axios.get(`item_types_view/filter?column=${column}&value=${value}`)
    }
}